
/* Define all configuration variables */

export const isDevEnvironment = process.env.NODE_ENV === "development"
export const appBuildVersion = process.env.REACT_APP_BUILD_VERSION || "development"
export const appTarget = process.env.REACT_APP_TARGET || "staging"
export let apiServerUrl = process.env.REACT_APP_SERVER_URL
export let defaultLanguage = "sv"
export const leagues = ["allsvenskan", "superettan"]
export const defaultLeague = leagues[0]
export const availableSeasons = ["2024", "2023", "2022", "2021", "2020", "2019", "2018", "2017"]
export let defaultSeason = availableSeasons[0]
export const queryCacheStaleTime = 15 * 60 * 1000
export const minimumStarRatingToShowInTimeline = 2
export const highlightsPlusMinRating = 4
export let ssoCheckoutUrl = "https://sef-checkout-experiments.web.app/"
export let ssoMyPageUrl = "https://minasidor-tst.svenskelitfotboll.se/"
export const sentryDSN = "https://06a3381fb2df49adb00c79fe46ff0a9b@o543475.ingest.sentry.io/6547807"
export let rudderstackKey = null
export let rudderstackPlaneUrl = "https://svenskelit-dataplane.rudderstack.com"
export let rudderstackEnableVideoEvents = false
export let showDebugColorPicker = false
export let prerolls = {
    "allsvenskan": "13187:0:3000_",
    "superettan": "13186:0:3000_",
}

export let teamToChannels = {
    1:  [ 14,  62,  63,  61],
    2:  [ 30,  66,  64,  65],
    3:  [ 45,  46,  47,  29],
    4:  [ 71,  70,  17,  72],
    5:  [ 75,  74,  73,   8],
    6:  [ 13,  76,  77,  78],
    7:  [ 36,  79,  80,  81],
    8:  [ 83,  20,  82,  84],
    9:  [ 24,  86,  85,  87],
    10: [ 89,  88,  90,  28],
    11: [  7,  92,  93,  91],
    12: [ 94,  96,  37,  95],
    13: [ 98,  97,  12,  99],
    14: [100,  15, 102, 101],
    15: [105, 103,  35, 104],
    16: [107, 106, 108,  21],
    17: [ 27, 110, 111, 109],
    19: [  4, 114, 113, 112],
    20: [117,  44, 115, 116],
    23: [ 11, 119, 118, 120],
    24: [122,   3, 121, 123],
    25: [124, 126, 125,  42],
    26: [128, 129, 127,  19],
    27: [132,  10, 131, 130],
    28: [ 38, 133, 134, 135],
    29: [138, 137, 136,  41],
    30: [141, 139, 140,  22],
    31: [143, 142,  18, 144],
    32: [146, 147,  39, 145],
    33: [  5, 150, 148, 149],
    34: [151, 152, 153,  26],
    36: [156,  33, 155, 154],
    37: [157, 159,  34, 158],
    39: [160, 162,   9, 161],
    48: [ 32, 165, 163, 164],
    49: [167,  43, 166, 168],
    50: [170, 171,  25, 169],
    51: [172, 174, 173,  16],
    52: [177,  31, 175, 176],
    53: [180, 178,  23, 179],
    54: [182, 183, 181,  40],
    55: [189, 194, 195, 196],
    56: [190, 191, 192, 193],
}

/* Update / provide defaults depending on what deployment we're doing */

if (isDevEnvironment) {
    // Dev
    // apiServerUrl = apiServerUrl || "http://localhost:8080/"
    apiServerUrl = apiServerUrl || "https://staging-api.forzasys.com/"
    // apiServerUrl = apiServerUrl || "https://api.forzify.com/"
    showDebugColorPicker = true
    // defaultLanguage = "en"
    // rudderstackKey = "2Tmrf7l0QMELxdH4sYZwCihUrZv" // TODO Temporary while developing
    prerolls = {
        "allsvenskan": "7869:0:3000_",
        "superettan": "7868:0:3000_",
    }

}  else if (appTarget === "staging") {

    // apiServerUrl = apiServerUrl || "https://staging-api.forzasys.com/"
    apiServerUrl = apiServerUrl || "https://staging-api.forzify.com/"
    showDebugColorPicker = true
    rudderstackKey = null // temporarily disabled
    prerolls = {
        "allsvenskan": "7869:0:3000_",
        "superettan": "7868:0:3000_",
    }
    // staging doesn't have latest data yet for 2024
    defaultSeason = availableSeasons[1]

} else {

    // apiServerUrl = apiServerUrl || "https://api.forzasys.com/"
    apiServerUrl = apiServerUrl || "https://api.fotbollplay.se/"
    ssoCheckoutUrl = "https://checkout.svenskelitfotboll.se/"
    ssoMyPageUrl = "https://minasidor.svenskelitfotboll.se/"
    rudderstackKey = "2Tmrf7l0QMELxdH4sYZwCihUrZv"
}


/* Export everything as an object too, if we prefer using Config.xyz */

const Config = {
    isDevEnvironment,
    appBuildVersion,
    appTarget,
    apiServerUrl,
    defaultLanguage,
    leagues,
    defaultLeague,
    availableSeasons,
    defaultSeason,
    queryCacheStaleTime,
    minimumStarRatingToShowInTimeline,
    highlightsPlusMinRating,
    ssoCheckoutUrl,
    sentryDSN,
    prerolls,
    rudderstackKey,
    rudderstackPlaneUrl,
    rudderstackEnableVideoEvents,
    showDebugColorPicker,
}
export default Config